export const AppName = "Pdp";
export const ShouldUseClarityAutomate = true;
export const FeatureFlags = [
  "PDP_Offer_Title_Brand_Sku",
  "PDP_Compare_Tool",
  "Add_To_List_Badge_Expanded_PDP",
  "Ribbon_Text_Dollar_Vs_Percentage",
  "PDP_Show_Fitment_Feedback_Internals",
  "PromotionPreview_PDP_Frontend",
  "PRP_FrontEnd_Promo_Preview",
  "Product_Comparison_Table_MightFit"
];
